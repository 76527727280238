import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
// import Card from "@material-ui/core/Card";
import Button from "@nodes/@material-ui/core/Button";
// import Grid from "@nodes/@material-ui/core/Grid";
import FormGroup from "@nodes/@material-ui/core/FormGroup";
import FormControlLabel from "@nodes/@material-ui/core/FormControlLabel";
import Checkbox from "@nodes/@material-ui/core/Checkbox";
// import CardContent from "@material-ui/core/CardContent";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import RenderPaymentDepositForm from "@shared/components/RenderPaymentDepositForm";
import RenderPaymentInfo from "@shared/components/RenderPaymentInfo";
import DisplayLastPaymentAlert from "@shared/components/DisplayLastPaymentAlert";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import NoLoanFound from "@shared/components/NoLoanFound";
// import LoadingInspect from "@shared/components/LoadingInspect";
import Loading from "@shared/components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { createPayment } from "../store/slices/payments";
import { selectLoanById, updateLoanToggle, updatePostPone } from "../store/slices/loans";
// import { printReceiptHandler } from "@shared/functions/print";
import { createQueue } from "../store/slices/syncronization";
import { MdRestore } from "@nodes/react-icons/md";
import { selectCommon } from "../store/slices/common";
import { getApplyPaymentsProp, getUniqueID } from "@shared/functions";
// import {
//   cobroPaymentCreateCollected,
//   cobroPaymentCreateApplyLoan,
//   cobroPaymentCreatePostData,
//   cobroSyncProps,
// } from "@shared/functions/validations";
import { useHistory } from "react-router-dom";
// import { alertSuccess } from "../store/slices/snackbar";
import {
  useGetOwnerConfigQuery,
  useGetPermissionsQuery,
  useGetRouteQuery,
  useGetUserConfigQuery,
} from "../store/slices/api";
import { useAlert } from "react-alert";
import Divider from "@material-ui/core/Divider";
import { selectCreateStatus, setCreatingData } from "../store/slices/ui";
import { Redirect } from "react-router-dom";

const PaymentCreate = () => {
  const autoHideFromDashboard = [
    2442, 2103, 2115, 2904, 2906, 2840, 2814, 2447, 2834, 2322, 2662, 2582, 2554, 2372, 2456, 2708, 2717, 2659,
  ];

  const directUrl = useRef(null);
  const history = useHistory();
  const alert = useAlert();
  const { id: money_id } = useParams();

  const dispatch = useDispatch();

  const currentLoan = useSelector((state) => selectLoanById(state, money_id));
  const isDatacreating = useSelector(selectCreateStatus);
  const common = useSelector(selectCommon);
  const [payment_id, setPaymentId] = useState("N/A");

  useEffect(() => {
    setPaymentId(getUniqueID(`PaymentP${currentLoan?.money_id}`));
    //eslint-disable-next-line
  }, []);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);

  if (isDatacreating === payment_id) return <Redirect to={directUrl.current} />;

  if (!currentLoan) return <NoLoanFound />;
  if (isDatacreating === `creating${payment_id}`) return <Loading />;

  const handlePaymentCreate = (data) => {
    dispatch(setCreatingData({ data_id: `creating${payment_id}` }));
    // localStorage.setItem("lastPaymentId", payment_id);

    const recData = getApplyPaymentsProp(data, currentLoan, common, payment_id);

    directUrl.current = `/payment/receipt/${recData.collected.payment_id}/${recData.collected.syncronization_id}/${data.autoPrintReceipt}`;

    // console.log(recData);
    // return;

    // await cobroPaymentCreateCollected.validate(recData.collected);
    // await cobroPaymentCreateApplyLoan.validate(recData.applyLoan);
    // await cobroPaymentCreatePostData.validate(recData.sync.postData);
    // await cobroSyncProps.validate(recData.sync.syncProps);

    // dispatch(applyPayment(recData.applyLoan));
    // dispatch(moveLoanlast({ money_id }));

    let isHidden = false;
    if (autoHideFromDashboard.includes(+common.owner_id)) {
      isHidden = true;
    }

    dispatch(createQueue(recData.sync));

    dispatch(createPayment({ ...recData.collected, isHidden }));

    // // dispatch(alertSuccess({ duration: 3500, message: "Pago registado con exito!" }));
    alert.success("Pago Aplicado!", { timeout: 3500, position: "top center" });

    // if (data.autoPrintReceipt === "1") {
    //   printReceiptHandler(
    //     { ...currentLoan, ...recData.collected },
    //     { ...userConfig, ...route, ...ownerConfig, ...common }
    //   );
    // }

    // history.replace(
    //   `/payment/receipt/${recData.collected.payment_id}/${recData.collected.syncronization_id}/${data.autoPrintReceipt}`
    // );
    // history.replace(`/dashboard`);
  };

  const postPoneLoan = () => {
    dispatch(updatePostPone(currentLoan));
    history.push("/dashboard");
  };

  const handleLoanToggle = () => {
    dispatch(updateLoanToggle({ money_id }));
  };

  if (!currentLoan || !route || !permissions || !userConfig || !ownerConfig) return <Loading />;

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentLoan}>
        <LoanDetailMenu loan={currentLoan} />
      </DebtorNameHeader>

      <DisplayLastPaymentAlert loan={currentLoan} />

      <Box component="div" p={1}>
        <DisplayLoanStatus loan={currentLoan} />
      </Box>

      <Box component="div" p={1}>
        <RenderPaymentInfo loan={currentLoan} />
      </Box>

      <Box component="div" p={1}>
        <RenderPaymentDepositForm
          config={{ ...ownerConfig, moraPercent: route.mora, moraWeeks: route.mWeeks }}
          loan={{
            ...currentLoan,
            user_id: common.user_id,
            permissions,
            payment_date: common.collect_week_date,
            today_date: common.today_date,
            creditor_id: common.creditor_id,
            owner_id: common.user_id,
          }}
          createPayment={handlePaymentCreate}
        />
      </Box>

      <Divider className="mt-3" />

      <Box component="div" p={1} paddingTop={4}>
        <Button
          startIcon={<MdRestore />}
          onClick={() => postPoneLoan(currentLoan)}
          variant="outlined"
          color="primary"
          fullWidth>
          Posponer para luego!
        </Button>
      </Box>

      <Box component="div" p={1} paddingTop={2}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                disabled={common.owner_id === "2172" ? true : false}
                checked={currentLoan.isHidden === true ? true : false}
                onChange={() => handleLoanToggle({ money_id: currentLoan.money_id })}
                name="autoPrintReceipt"
                color="secondary"
              />
            }
            label="Ocultar en la lista de Cobro!"
          />
        </FormGroup>
      </Box>
    </div>
  );
};

export default PaymentCreate;
